import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'tk-form-field-text-counter',
	standalone: true,
	templateUrl: './form-field-text-counter.component.html',
	styleUrl: './form-field-text-counter.component.sass',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [CommonModule],
})
export class FormFieldTextCounterComponent {
	@Input() currentCount: number = 0;
	@Input() maxCount: number = 0;
}
